import { dateBuilder } from "../../../services/general"

export const bookingZendeskTemplate = ({
  mentalHealthState,
  userData,
  addresses,
}) => {

  const {
    hasConsultedWithPsychOutsideProgram,
    experienceWithPsychOutsideProgram,
    hasConsultedWithPsychInProgram,
    previousPsychName,
    hasBeenDiagnosed,
    mentalIllnessDiagnosis,
    hasFamilyHistory,
    specificFamilyHistory,
    hasBeenHospitalized,
    specificHospitalizationDetails,
    isTakingPsychMeds,
    psychMedsDetails,
    isTakingNonPsychMeds,
    nonPsychMedsDetails,
    hasDrugAllergies,
    drugAllergiesDetails,
    causeOfDistress,
    specificCauseOfDistress,
    durationCurrentCauseOfDistress,
    currentMentalHealthCondition,

    kesslerNervous,
    kesslerHopeless,
    kesslerRestless,
    kesslerDepressed,
    kesslerEffort,
    kesslerWorthless,
    hasWishedToBeDead,
    hadSuicidalThoughts,

    hasThoughtSuicidalMethod,
    hadSuicidalIntent,
    hadSuicidalIntentWithPlan,
    hasActedOnSuicidalThoughts,
    hasActedOnThoughtsWithinThreeMonths,
    hasThoughtsOfHarmingOthers,
    hasCopingAttempts,

    firstChoiceSchedule,
    secondChoiceSchedule,
    thirdChoiceSchedule,
    ifScheduleUnavailable,
    therapistPreference,
    otherNotes,
  } = mentalHealthState

  let {
    firstName,
    lastName,
    birthday,
    mobileNumber,
    email,
    employeeNumber,
    site,
    department,
    emergencyContact,
    gender,
  } = userData

  let {
    streetAddress,
    barangay,
    city,
    province
  } = addresses?.addresses[0]

  // dynamically creates Zendesk templates based on availability of information
  // checks if the values is truthy -> meaning the value is not undefined, null, 0, "", NaN, and false
  // Personal Information
  const bodyFirstName = !!firstName ? `First Name: ${firstName}\n`: ""
  const bodyLastName = !!lastName ? `Last Name: ${lastName}\n`: ""
  const bodyBirthday = !!birthday ? `Birthday: ${dateBuilder(birthday)}\n`: ""
  const bodyGender = !!gender ? `Gender: ${gender}\n` : ""
  const bodyEmail = !!email ? `Email: ${email}\n` : ""
  const bodyMobileNumber = !!mobileNumber ? `Mobile Number: ${mobileNumber}\n` : ""
  const bodyAddress = addresses ? `Address: ${streetAddress}, ${barangay}, ${city}, ${province}\n` : ""
  const personalInformationBody = `- Personal Information -\n` +
  bodyFirstName + bodyLastName + bodyBirthday + bodyGender + bodyEmail + bodyMobileNumber + bodyAddress

  // Mental Health Information
  const causeOfDistressBody = !!causeOfDistress ? `What made you seek mental health support? ${causeOfDistress}\n` : ""
  const specificCauseOfDistressBody = !!specificCauseOfDistress ? `Please tell us more about your concern/s. ${specificCauseOfDistress}\n` : ""
  const durationCurrentCauseOfDistressBody = !!durationCurrentCauseOfDistress ? `How long have you been experiencing this? ${durationCurrentCauseOfDistress}\n` : ""
  const currentMentalHealthConditionBody = !!currentMentalHealthCondition ? `What are you currently experiencing? ${currentMentalHealthCondition}\n` : ""
  const mentalHealthInformationBody = `\n- Mental Health Information -\n` +
  causeOfDistressBody + specificCauseOfDistressBody + durationCurrentCauseOfDistressBody + currentMentalHealthConditionBody

  // Medical History
  const bodyHasConsultedWithPsychOutsideProgram = !!hasConsultedWithPsychOutsideProgram ? `Have you consulted a psychologist/psychiatrist in any other setting before? ${hasConsultedWithPsychOutsideProgram}\n` : ""
  const bodyExperienceWithPsychOutsideProgram = hasConsultedWithPsychOutsideProgram === "Yes" ? !!experienceWithPsychOutsideProgram ? `How was your experience? ${experienceWithPsychOutsideProgram.label}\n` : "" : ""
  const bodyHasConsultedWithPsychInProgram = !!hasConsultedWithPsychInProgram ? `Have you consulted a psychologist/psychiatrist under MedGrocer before? ${hasConsultedWithPsychInProgram}\n` : ""
  const bodyPreviousPsychName = hasConsultedWithPsychInProgram === "Yes" ? !!previousPsychName ? `Who among our mental health professionals have you consulted with before? ${previousPsychName}\n` : "" : ""
  const bodyHasBeenDiagnosed = !!hasBeenDiagnosed ? `Have you ever been diagnosed with a mental condition? ${hasBeenDiagnosed}\n` : ""
  const bodyMentalIllnessDiagnosis = hasBeenDiagnosed === "Yes" ? !!mentalIllnessDiagnosis ? `When were you diagnosed? What were you diagnosed with? ${mentalIllnessDiagnosis}\n` : "" : ""
  const bodyHasFamilyHistory = !!hasFamilyHistory ? `Is there a history of mental condition in your family, including relatives? ${hasFamilyHistory}\n` : ""
  const bodySpecificFamilyHistory = hasFamilyHistory === "Yes" ? !!specificFamilyHistory ? `Who in your family was diagnosed? What were they diagnosed with? ${specificFamilyHistory}\n` : "" : ""
  const bodyHasBeenHospitalized = !!hasBeenHospitalized ? `Have you been hospitalized for mental health reasons? ${hasBeenHospitalized}\n` : ""
  const bodySpecificHospitalizationDetails = hasBeenHospitalized === "Yes" ? !!specificHospitalizationDetails ? `When were you hospitalized? What was the cause?  ${specificHospitalizationDetails}\n` : "" : ""
  const bodyIsTakingPsychMeds = !!isTakingPsychMeds ? `Have you taken or are currently taking psychiatric medicines? ${isTakingPsychMeds}\n` : ""
  const bodyPsychMedDetails = isTakingPsychMeds === "Yes" ? !!psychMedsDetails ? `Which meds are these? What dose? ${psychMedsDetails}\n` : "" : ""
  const bodyIsTakingNonPsychMeds = !!isTakingNonPsychMeds ? `Are you currently taking non-psychiatric medicines? ${isTakingNonPsychMeds}\n` : ""
  const bodyNonPsychMedDetails = isTakingNonPsychMeds === "Yes" ? !!nonPsychMedsDetails ? `ch meds are these? What dose? ${nonPsychMedsDetails}\n` : "" : ""
  const bodyHasDrugAllergies = !!hasDrugAllergies ? `Do you have drug allergies? ${hasDrugAllergies}\n` : ""
  const bodyDrugAllergiesDetails = hasDrugAllergies === "Yes" ? !!drugAllergiesDetails ? `Which drugs are these? ${drugAllergiesDetails}\n` : "" : ""
  const medicalHistoryBody = `\n- Medical History -\n` +
  bodyHasConsultedWithPsychOutsideProgram + bodyExperienceWithPsychOutsideProgram + bodyHasConsultedWithPsychInProgram +
  bodyPreviousPsychName + bodyHasBeenDiagnosed + bodyMentalIllnessDiagnosis + bodyHasFamilyHistory + bodySpecificFamilyHistory +
  bodyHasBeenHospitalized + bodySpecificHospitalizationDetails + bodyIsTakingPsychMeds + bodyPsychMedDetails +
  bodyIsTakingNonPsychMeds + bodyNonPsychMedDetails + bodyHasDrugAllergies + bodyDrugAllergiesDetails

  // Screening
  const kesslerNervousBody = !!kesslerNervous ? `Nervous? ${kesslerNervous}\n` : ""
  const kesslerHopelessBody = !!kesslerHopeless ? `Hopeless? ${kesslerHopeless}\n` : ""
  const kesslerRestlessBody = !!kesslerRestless ? `Restless or fidgety? ${kesslerRestless}\n` : ""
  const kesslerDepressedBody = !!kesslerDepressed ? `So depressed that nothing could cheer you up? ${kesslerDepressed}\n` : ""
  const kesslerEffortBody = !!kesslerEffort ? `Everything needed effort to accomplish? ${kesslerEffort}\n` : ""
  const kesslerWorthlessBody = !!kesslerWorthless ? `Worthless? ${kesslerWorthless}\n` : ""
  const hasWishedToBeDeadBody = !!hasWishedToBeDead ? `In the past 30 days, have you wished you were dead or wished you could go to sleep and not wake up? ${hasWishedToBeDead}\n` : ""
  const hadSuicidalThoughtsBody = !!hadSuicidalThoughts ? `In the past 30 days, have you actually had any thoughts of killing yourself? ${hadSuicidalThoughts}\n` : ""
  const screeningBody = `\n- Screening -\n` +
  kesslerNervousBody + kesslerHopelessBody + kesslerRestlessBody + kesslerDepressedBody + kesslerEffortBody +
  kesslerWorthlessBody + hasWishedToBeDeadBody + hadSuicidalThoughtsBody;

  // Assessment if Yes to both hasWishedToBeDead and hadSuicidalThoughts
  const hasThoughtSuicidalMethodBody = !!hasThoughtSuicidalMethod ? `Have you been thinking about how you might do this? ${hasThoughtSuicidalMethod}\n` : ""
  const hadSuicidalIntentBody = !!hadSuicidalIntent ? `Have you had these thoughts and had some intention of acting on them? ${hadSuicidalIntent}\n` : ""
  const hadSuicidalIntentWithPlanBody = !!hadSuicidalIntentWithPlan ? `Have you started to work out or worked out the details of how to kill yourself? Do you intend to carry out this plan? ${hadSuicidalIntentWithPlan}\n` : ""
  const hasActedOnSuicidalThoughtsBody = !!hasActedOnSuicidalThoughts ? `Have you ever done anything, started to do anything, or prepared to do anything to end your life? ${hasActedOnSuicidalThoughts}\n` : ""
  const hasActedOnThoughtsWithinThreeMonthsBody = hasActedOnSuicidalThoughts === "Yes" ? !!hasActedOnThoughtsWithinThreeMonths ? `Was this within the past 3 months? ${hasActedOnThoughtsWithinThreeMonths}\n` : "" : ""
  const hasThoughtsOfHarmingOthersBody = !!hasThoughtsOfHarmingOthers ? `Do you have any thoughts of harming others? ${hasThoughtsOfHarmingOthers}\n` : ""
  const hasCopingAttemptsBody = !!hasCopingAttempts ? `How have you tried to cope with your concern/s? Were these helpful or not helpful? ${hasCopingAttempts}\n` : ""
  let assessmentBody = `\n-Assessment-\n` +
  hasThoughtSuicidalMethodBody + hadSuicidalIntentBody + hadSuicidalIntentWithPlanBody + hasActedOnSuicidalThoughtsBody +
  hasActedOnSuicidalThoughtsBody + hasActedOnThoughtsWithinThreeMonthsBody + hasThoughtsOfHarmingOthersBody + hasCopingAttemptsBody;

  // removes content of assessmentBody if assessment is not taken
  if (!!!hasThoughtSuicidalMethod){
    assessmentBody = ""
  }

  const firstChoiceScheduleBody = !!firstChoiceSchedule ? `Choice 1: ${firstChoiceSchedule?.day?.value}, ${firstChoiceSchedule?.time?.value}\n` : ""
  const secondChoiceScheduleBody = !!secondChoiceSchedule ? `Choice 2: ${secondChoiceSchedule?.day?.value}, ${secondChoiceSchedule?.time?.value}\n` : ""
  const thirdChoiceScheduleBody = !!thirdChoiceSchedule ? `Choice 3: ${thirdChoiceSchedule?.day?.value}, ${thirdChoiceSchedule?.time?.value}\n` : ""
  const ifScheduleUnavailableBody = !!ifScheduleUnavailable ? `If my preferred schedule is not available within the next 7 days… ${ifScheduleUnavailable}\n` : ""
  const therapistPreferenceBody = !!therapistPreference ? `Therapist preference? ${therapistPreference}\n` : ""
  const otherNotesBody = !!otherNotes ? `Thank you for answering this intake form. Is there anything else you would like us to know? ${otherNotes}\n` : ""
  const preferenceBody = `\n- Preference -\n` +
  firstChoiceScheduleBody + secondChoiceScheduleBody + thirdChoiceScheduleBody + ifScheduleUnavailableBody +
  therapistPreferenceBody + otherNotesBody

  return personalInformationBody + medicalHistoryBody + mentalHealthInformationBody + screeningBody + assessmentBody + preferenceBody
}
